import React from "react";
import styled from "styled-components";
import contentHd from "../../assets/images/contentHead.svg";
import { motion } from "framer-motion";

const Content = ({ children }) => {
  return (
    <Container>
      <div className="frameComtainer"></div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          type: "tween",
          duration: 0.3,
          // ease: [0.18],
        }}
      >
        {children}
      </motion.div>
    </Container>
  );
};

export default Content;

const Container = styled.section`
  width: calc(100% - 16%);
  min-height: 100vh;
  overflow-y: scroll;
  background-color: #f4f3f3;
  margin-left: 16%;
  /* position: absolute; */
  /* right: 0; */
  @media only screen and (max-width: 1147px) {
    width: calc(100%);
    margin-left: 0;
  }

  .frameComtainer {
    background: linear-gradient(rgba(0, 9, 13, 0.35), rgba(0, 9, 13, 0.298)),
      url(${contentHd}), no-repeat, center;
    width: 100%;
    height: 200px;
    img {
      object-fit: cover;
      width: 100vw;
      height: 240px;
    }
  }
  .children {
  }
`;
